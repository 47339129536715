import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Callback = ({ onLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();  // Verwende useNavigate statt useHistory

  useEffect(() => {
    const sendCodeToBackend = async (code) => {
      try {
        const response = await axios.post(
//          'http://127.0.0.1/backend/auth_callback.php',
            'https://fuchsbot.ocame.de/backend/auth_callback.php',

          { code },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );

        if (response.data.success) {
          onLogin(response.data.user);
          navigate('/chapterText');  // Verwende navigate statt history.push
          window.location.reload();
        } else {
          console.error('Failed to authenticate');
          navigate('/');
        }
      } catch (error) {
        console.error('Error sending code to backend:', error);
      }
    };

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    if (code) {
      sendCodeToBackend(code);
    }
  }, [location.search, navigate, onLogin]);  // �berpr�fe die Abh�ngigkeiten

  return <div>Authentifizierung läuft...</div>;
};

export default Callback;
