import React, {useState, useEffect} from 'react';
import {Table, Button, Form, Input, message, Space, ColorPicker, Switch   } from 'antd';
import api from '../axios';

const EditableTable = () => {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [form] = Form.useForm();
    const [editingId, setEditingId] = useState('');


    const isEditing = (record) => record.id === editingId;

    const edit = (record) => {
        if(editingId !== "")
            return
        form.setFieldsValue({ ...record });
        setEditingId(record.id);
    };

    const cancel = () => {
        setEditingId('');
    };

    // Neuer Datensatz erstellen
    const addNewRecord = () => {
        const newRecord = {
            id: 0, // ID 0 für den neuen Datensatz
            name: '',
            storyText: '',
            color: '#2499f5',
            imageUrl: '',
            active: true
        };
        setData([newRecord, ...data]);
        edit(newRecord);
    };

    // Änderungs speicherung
    const save = async (id) => {
        try {
            const newData = [...data];
            const index = newData.findIndex((item) => id === item.id);

            if (index > 0) {
                form.validateFields().then(values => {
                    api.post('/dataStoryText.php', { id: editingId,  action: "update",...values})
                        .then(response => {
                            setData(data.map(item => item.id === editingId ? {...item, ...values} : item));
                            message.success('Datensatz erfolgreich aktualisiert');
                        })
                        .catch(error => {
                            message.error('Fehler beim Aktualisieren des Datensatzes');
                        });
                });

                setEditingId('');
            } else {
                form.validateFields().then(values => {
                    api.post('/dataStoryText.php', { id: editingId, action: "update", ...values})
                        .then(response => {
                            const newRecord = response.data.updated;
                            newData.splice(index, 1);
                            // Füge den neuen Datensatz mit der tatsächlichen ID hinzu
                            setData([...newData, newRecord]);
                            //newData.push(newRecord);
                            //setData(newData);
                            message.success('Datensatz erfolgreich hinzugefügt');
                        })
                        .catch(error => {
                            message.error('Fehler beim Hinzufügen des neuen Datensatzes');
                        });
                });

                setEditingId('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    useEffect(() => {
        api.get('/dataStoryText.php')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                message.error('Fehler beim Laden der Daten');
            });

    }, []);


    // Filter
    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };


    const filteredDataSource = data.filter((item) => {
        const matchesAddress = item.storyText
            .toLowerCase()
            .includes(searchText.toLowerCase());
        return item.id === 0 || (matchesAddress);
    });

    const handleActiveChange = (checked, record) => {
        if(record.id === 0)
            return
        const newData = data.map((item) => {
            if (item.id === record.id) {
                return { ...item, active: checked }; // Update the color in the record
            }
            return item;
        });
        const id = record.id;
        const active = checked;
        const action = "activeChange"
        api.post('/dataStoryText.php', { id: id, active: active, action: action})
            .then(response => {
                message.success('Datensatz erfolgreich aktualisiert');
            })
            .catch(error => {
                message.error('Fehler beim Aktualisieren des Datensatzes');
            });
        setData(newData); // Update the state with the modified dataSource
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            hidden: true
        },
        {
            title: 'Aktiv',
            dataIndex: 'active',
            key: 'active',
            editable: false,
            render: (active, record) => (
                <Switch checked={active} onChange={(checked) => handleActiveChange(checked ,record)}  checkedChildren="An" unCheckedChildren="Aus" />
            )
        },
        {
            title: 'Farbe',
            dataIndex: 'color',
            key: 'color',
            editable: true,
            width: 50,
            inputType: "color",
            render: (_, record) => (
                <div
                    style={{
                        width: '24px',
                        height: '24px',
                        backgroundColor: record.color,
                        border: '1px solid #ddd',
                    }}
                />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            required: true,
            editable: true,
            width: 150,
            inputType: "text",
        },
        {
            title: (
                <Space>
                    Text
                    <Input
                        placeholder=""
                        value={searchText}
                        onChange={handleSearchTextChange}
                        style={{ width: 150 }}
                    />
                </Space>
            ),
            dataIndex: 'storyText',
            key: 'storyText',
            defaultSortOrder: 'descend',
            editable: true,

            required: true,
            inputType: "textArea",
            render: (text) => (
                <span>
        {text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ))}
      </span>
            ),
        },
        {
            title: 'Bild',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            width: 150,
            editable: true,
            render: (text, record) =>
                record.imageUrl ? (
                    <img
                        src={record.imageUrl}
                        alt={record.name}
                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                    />
                ) : null
        },
        {
            title: 'Aktion',
            width: 250,
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Button
                onClick={() => save(record.id)}
                style={{ marginRight: 8 }}
            >
              Speichern
            </Button>
            <Button onClick={cancel}>Abbrechen</Button>
          </span>
                ) : (
                    <Button disabled={editingId !== ''} onClick={() => edit(record)}>
                        Bearbeiten
                    </Button>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                form: form, // Hier wird das form-Objekt weitergegeben
            }),
        };
    });

    return (
        <div>
            <Button onClick={addNewRecord} type="primary" style={{ marginBottom: 16 }}>
                Neuen Datensatz erstellen
            </Button>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered

                    columns={mergedColumns}
                    dataSource={filteredDataSource}
                    rowClassName="editable-row"
                    onRow={(record) => ({
                        onDoubleClick: () => {
                            edit(record); // Bearbeitungsmodus durch Doppelklick aktivieren
                        },
                    })}
                    pagination={false}
                />
            </Form>
        </div>
    );
};


const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          required,
                          form, // Empfang des form-Objekts
                          ...restProps
                      }) => {
    const [inputNode, setInputNode] = useState('');



    useEffect(() => {
        switch (inputType) {
            case "color":
                setInputNode (
                    <ColorPicker
                        value={record.color}
                        onChange={(value) => {
                            form.setFieldsValue({ color: "#" + value.toHex() });
                        }}
                    />);
                break;
            case "textArea":
                setInputNode (<Input.TextArea showCount maxLength={4096} />);
                break;
            case "image":
                break;
            default:
                setInputNode (<Input />);
        }
    }, [inputType]); // Nur neu rendern, wenn sich inputType ändert




    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: required || false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableTable;
