import React, { useState } from 'react';
import {Button, Row, Col, message} from 'antd'
import api from '../axios';
import EditableTableChannel from "../components/EditableTableChannels";

const Option = () => {
    const [loadings, setLoadings] =  useState(false);

    const startCheck = () => {
        if(loadings === false) {
            setLoadings(true);
            api.post('/options.php', { action: "startCheck"})
                .then(response => {
                    setLoadings(false);
                    if(response.data.status	=== "success")
                        message.success('Prüfung wurde ausgeführt');
                    else
                        message.error('Fehler bei der Prüfung');
                })
                .catch(error => {
                    message.error('Fehler bei der Prüfung');
                });
        }
    };

   return (
        <div>
            <Row>
                <Col span={10}  push={2}><EditableTableChannel /></Col>
                <Col span={10} push={3} >
                    <p>Bot sofort Auslösen, und Prüfung auf neuen Inhalt starten</p>
                    <Button danger type="primary" loading={loadings} block style={{ height: '200px' }} onClick={startCheck}>Jetzt Prüfen</Button>
                </Col>
            </Row>
        </div>
    );
};

export default Option