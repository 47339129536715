import React from "react";
import EditableTableStory from '../components/EditableTableStory';
import EditableTableStoryTexte from "../components/EditableTableStoryTexte";
import {Col, Row} from "antd";

const storyOption = () => {
    return (
        <div>
            <Row>
                <Col span={20}  push={2}>
            <h1>Story Übersicht</h1>
            <p>Eine kleine Übersicht der erkannten Storys, der Name kann geändert werden, wenn eine Kürzere bzw. Alternative Version für die Ankündigung neuer Kapitel benutz werden soll haben möchte</p>
            <p>Der Tag fungiert als Verknüpfung, die eine Geschichte mit den zugehörigen Kapiteltexten verbindet.<br />

                Wenn du beispielsweise der Geschichte "Blue Jay Storys" den Tag "Blue Jay" zuweist und in "Kapitel Texte" verschiedene Einträge ebenfalls mit dem Tag "Blue Jay" versiehst, werden bei neuen Veröffentlichungen in der Geschichte "Blue Jay Storys" genau die Texte verwendet, die diesen "Blue Jay"-Tag haben.</p>
            <EditableTableStory />

            <h1>Story Publikations Texte</h1>
            <p>Zum Einstellen der Bot-Texte beim Erscheinen einer neuen Gesichte eines Favorisierten Autors.</p>
            <p>Mit der Ausgabe erfolgt noch keine Automatisiertes Folgen der Gesichte für die Ankündigung neuer Kapitel</p>
            <p><b>Aktiv</b> Schaltet den Text aus bzw. wieder ein</p>
            <p><b>Farbe:</b> (optional) Einstellung der Farbe des Balken Links von der Box</p>
            <p><b>Name:</b> (intern) Name des Textes, wird nicht in der Ausgabe benutzt, dient nur zum besseren Zuordnen</p>
            <p><b>Text:</b> Der Text der in der Box Angezeigt wird. Folgenten Platzhalter sind möglich:<br />
            <b>%storyName%"</b> - Name der Gesichte und <b>%autor%"</b> - Name des Autors der Gesichte
    </p>
            <p><b>Bild:</b> (optional) Welches Bild wird bei der Ausgabebox angezeigt</p>
            <EditableTableStoryTexte />
                </Col>
            </Row>
        </div>
    );
};

export default storyOption