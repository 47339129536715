import React, {useState, useEffect} from 'react';
import {Table, Button, Form, Input, message, Tag, Select, Space, ColorPicker, Switch, Modal, Spin  } from 'antd';
import api from '../axios';

const { Option } = Select;

const EditableTable = () => {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedStories, setSelectedStories] = useState([]);
    const [storyOptions, setStoryOptions] = useState([]);
    const [channels, setChannels] = useState([]);

    const [form] = Form.useForm();
    const [editingId, setEditingId] = useState('');
    const [testId, setTestId] = useState('');

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = React.useState(false);


    const isEditing = (record) => record.id === editingId;

    const edit = (record) => {
        if(editingId !== "")
            return;
        form.setFieldsValue({ ...record });
        setEditingId(record.id);
    };

    const cancel = () => {
        setEditingId('');
    };

    // Neuer Datensatz erstellen
    const addNewRecord = () => {
        if(data.some(item => item.id === 0))
            return;
        const newRecord = {
            id: 0, // ID 0 für den neuen Datensatz
            name: '',
            chapterText: '',
            storys: [],
            color: '#2499f5',
            imageUrl: '',
            active: true
        };
        setData([newRecord, ...data]);
        edit(newRecord);
    };

    // Änderungs speicherung
    const save = async (id) => {
        try {
            const newData = [...data];
            const index = newData.findIndex((item) => id === item.id);

            if (index > 0) {
                form.validateFields().then(values => {
                    api.post('/data.php', { id: editingId, action: "update", ...values})
                        .then(response => {
                            setData(data.map(item => item.id === editingId ? {...item, ...values} : item));
                            message.success('Datensatz erfolgreich aktualisiert');
                        })
                        .catch(error => {
                            message.error('Fehler beim Aktualisieren des Datensatzes');
                        });
                });

                setEditingId('');
            } else {
                form.validateFields().then(values => {
                    api.post('/data.php', { id: editingId, action: "update", ...values})
                        .then(response => {
                            const newRecord = response.data.updated;
                            newData.splice(index, 1);
                            // Füge den neuen Datensatz mit der tatsächlichen ID hinzu
                            setData([...newData, newRecord]);
                            //newData.push(newRecord);
                            //setData(newData);
                            message.success('Datensatz erfolgreich hinzugefügt');
                        })
                        .catch(error => {
                            message.error('Fehler beim Hinzufügen des neuen Datensatzes');
                        });
                });

                setEditingId('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await Promise.all([
                    api.get('/data.php')
                        .then(response => {
                            setData(response.data);
                        }),
                    api.get('/data.php?storys=true')
                        .then(response => {
                            setStoryOptions(response.data);
                        }),
                    api.get('/options.php')
                        .then(response => {
                            if (response.data.channels) {
                                let obj = JSON.parse(response.data.channels);
                                var newdata = [];
                                for (let i = 0; i < obj.length; i++) {
                                    newdata.push({
                                            active: true,
                                            "id": obj[i]["id"],
                                            "name": obj[i]["name"],
                                            "server": obj[i]["serverName"],
                                        }
                                    );
                                }
                                setChannels(newdata);
                            }
                        })
                ]);


            } catch (error) {
                message.error('Fehler beim Laden der Daten');
                setLoading(true); // Bei Fehler wird auf false gesetzt
            }
            setLoading(false);
        }
        fetchData();
    }, []);





    // Filter
    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleStoryFilterChange = (value) => {
        setSelectedStories(value);
    };

    const filteredDataSource = data.filter((item) => {
        const matchesAddress = item.chapterText
            .toLowerCase()
            .includes(searchText.toLowerCase());
        const matchesStories =
            selectedStories.length === 0 ||
            item.storys.some((story) => selectedStories.includes(story));
        return item.id === 0 || (matchesAddress && matchesStories);
    });

    const handleActiveChange = (checked, record) => {
        const newData = data.map((item) => {
            if (item.id === record.id) {
                return { ...item, active: checked }; // Update the color in the record
            }
            return item;
        });
        const id = record.id;
        const active = checked;
        const action = "activeChange"
        api.post('/data.php', { id: id, active: active, action: action})
            .then(response => {
                message.success('Datensatz erfolgreich aktualisiert');
            })
            .catch(error => {
                message.error('Fehler beim Aktualisieren des Datensatzes');
            });
        setData(newData); // Update the state with the modified dataSource
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            hidden: true
        },
        {
            title: 'Aktiv',
            dataIndex: 'active',
            key: 'active',
            editable: false,
            render: (active, record) => (
                <Switch checked={active} onChange={(checked) => handleActiveChange(checked ,record)} checkedChildren="An" unCheckedChildren="Aus" />
            )
        },
        {
            title: 'Farbe',
            dataIndex: 'color',
            key: 'color',
            editable: true,
            width: 50,
            inputType: "color",
            render: (_, record) => (
                <div
                    style={{
                        width: '24px',
                        height: '24px',
                        backgroundColor: record.color,
                        border: '1px solid #ddd',
                    }}
                />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            required: true,
            editable: true,
            width: 150,
            inputType: "text",
        },
        {
            title: (
                <Space>
                    Text
                    <Input
                        placeholder=""
                        value={searchText}
                        onChange={handleSearchTextChange}
                        style={{ width: 150 }}
                    />
                </Space>
            ),
            dataIndex: 'chapterText',
            key: 'chapterText',
            defaultSortOrder: 'descend',
            editable: true,

            required: true,
            inputType: "textArea",
            render: (text) => (
                <span>
        {text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ))}
      </span>
            ),
        },
        {
            title: <Space>
                Storys
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Filter by Story"
                    onChange={handleStoryFilterChange}
                    style={{ width: 150 }}
                >
                    {storyOptions.map(story => (
                        <Option key={story.id} value={story.name}>
                            {story.name.charAt(0).toUpperCase() + story.name.slice(1)}
                        </Option>
                    ))}
                </Select>
            </Space>,
            dataIndex: 'storys',
            key: 'storys',
            inputType: "story",
            editable: true,
            width: 250,
            render: (_, {storys}) => (
                <>
                    {storys.map((tagId) => {
                        const tag = storyOptions.find(option => option.id === tagId);

                        return tag ? (
                            <Tag color='geekblue' key={tagId}>
                                {tag.name.toUpperCase()}
                            </Tag>
                        ) : null;
                    })}
                </>
            ),
        },
        {
            title: 'Bild',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            width: 150,
            editable: true,
            render: (text, record) =>
                record.imageUrl ? (
                    <img
                        src={record.imageUrl}
                        alt={record.name}
                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                    />
                ) : null
        },
        {
            title: 'Aktion',
            width: 250,
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                <Button onClick={() => save(record.id)} style={{ marginRight: 8 }}>Speichern</Button>
                <Button onClick={cancel}>Abbrechen</Button>
            </span>
                ) : (
                    <Space>
                        <Button disabled={editingId !== ''} onClick={() => edit(record)}>Bearbeiten</Button>
                        <Button onClick={() => showModal(record)}>Testen</Button>
                    </Space>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                storyOptions: storyOptions,
                form: form, // Hier wird das form-Objekt weitergegeben
            }),
        };
    });

    const [testData, setTestData] = useState({
        storyName: 'Fin der Tester',
        chapterName: 'Von Kronleuchter und Ankern',
        author: 'Fin',
        target: '',
    });

    const showModal = (record) => {
        setIsModalVisible(true);
        setTestId(record.id);
    };

    const handleOk = () => {
        // Sende Daten an das PHP-Skript
        api.post('/test.php', { ...testData, action: "chapter", id: testId })
            .then(response => {
                if (response.data.status === 'Success') {
                    message.success('Test erfolgreich!');
                } else {
                    message.error('Test fehlgeschlagen!');
                }
            })
            .catch(error => {
                message.error('Fehler beim Senden der Daten');
            });
        setIsModalVisible(false);
        setTestId('');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setTestId('');
    };

    return (
        <div>
            <Spin spinning={loading}>
            <Button onClick={addNewRecord} type="primary" style={{ marginBottom: 16 }}>
                Neuen Datensatz erstellen
            </Button>
            <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered

                columns={mergedColumns}
                dataSource={filteredDataSource}
                rowClassName="editable-row"
                onRow={(record) => ({
                    onDoubleClick: () => {
                        edit(record); // Bearbeitungsmodus durch Doppelklick aktivieren
                    },
                })}
                pagination={false}
            />
            </Form>
            </Spin>

    <Modal title="Testen" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form layout="vertical">
            <Form.Item label="Storyname">
                <Input value={testData.storyName} onChange={e => setTestData({ ...testData, storyName: e.target.value })} />
            </Form.Item>
            <Form.Item label="Kapitelname">
                <Input value={testData.chapterName} onChange={e => setTestData({ ...testData, chapterName: e.target.value })} />
            </Form.Item>
            <Form.Item label="Autor">
                <Input value={testData.author} onChange={e => setTestData({ ...testData, author: e.target.value })} />
            </Form.Item>
            <Form.Item label="Ziel">
                <Select onChange={value => setTestData({ ...testData, target: value })}>
                    {channels.map(channel => (
                        <Option key={channel.id} value={channel.id}>
                            {channel.name.charAt(0).toUpperCase() + channel.name.slice(1)}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    </Modal>
        </div>
    );
};


const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          required,
                          color,
                          form, // Empfang des form-Objekts
                          storyOptions,
                          ...restProps
                      }) => {
    const [inputNode, setInputNode] = useState('');



    useEffect(() => {
        switch (inputType) {
            case "story":
                setInputNode(
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder="Story"

                        style={ { width: 150 } } >
                        {
                            storyOptions.map(story => (
                                <Option key={story.id} value={story.id}>
                                    {story.name.charAt(0).toUpperCase() + story.name.slice(1)}
                                </Option>
                            ))
                        }
                    </Select>
                );
                break;
            case "color":
                setInputNode (
                    <ColorPicker
                        value={record.color}
                        onChange={(value) => {
                            form.setFieldsValue({ color: "#" + value.toHex() });
                        }}
                    />);
                break;
            case "textArea":
                setInputNode (<Input.TextArea showCount autoSize={{ minRows: 10 }} maxLength={4096} />);
                break;
            case "image":
                break;
            default:
                setInputNode (<Input />);
        }
    }, [inputType]); // Nur neu rendern, wenn sich inputType ändert




    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: required || false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableTable;
