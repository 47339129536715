import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { HappyProvider } from '@ant-design/happy-work-theme';
import { Layout, Menu } from 'antd';
import {
  TableOutlined,
  HomeOutlined,
  LoginOutlined,
  ProfileOutlined,
  BookOutlined,
  ToolOutlined
} from '@ant-design/icons';
import LoginButton from './components/LoginButton';

import Callback from './components/Callback';
import ChapterText from "./pages/chapterText";
import StoryOption from "./pages/storyOption";
import Option from "./pages/option";
import api from './axios';


const { Header, Content } = Layout;

function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Verwende useNavigate für die Navigation
  const location = useLocation();

  const getSelectedMenuItem = () => {
    switch (location.pathname) {
      case '/':
        return 'home';
      case '/chapterText':
        return 'chapterText';
      case '/table':
        return 'table';
      case '/storyOption':
        return 'storyOption';
      case '/option':
        return 'option';
      default:
        return '';
    }
  };

  const selectedMenuItem = getSelectedMenuItem();

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await api.get('/check_login.php', {
          withCredentials: true, // Cookies werden mitgesendet
        });
        if (response.data.user) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };
    
    checkLoginStatus();
  }, []);

  const handleMenuClick = (e) => {
    if (e.key === 'home') {
      navigate('/'); // Navigiere zur Startseite
    } else if (e.key === 'login') {
      navigate('/auth/callback'); // Navigiere zur Login-Seite (oder wohin auch immer du den Benutzer führen möchtest)
    }
    else {
      navigate('/' + e.key);
    }
  };

  // Menüeinträge als Array von Objekten definieren
  const menuItems = [
    {
      label: 'Home',
      key: 'home',
      icon: <HomeOutlined />,
    },
    user && {
      label: 'Kapitel Texte',
      key: 'chapterText',
      icon: <ProfileOutlined />,
      href: '/chapterText'
    },
    user && {
      label: 'Story Einstellungen',
      key: 'storyOption',
      icon: <BookOutlined />,
      href: '/storyOption'
    },
    user && {
      label: 'Bot Einstellungen',
      key: 'option',
      icon: <ToolOutlined />,
      href: '/option'
    }

  ].filter(Boolean); // filter(Boolean) entfernt alle `false`-Werte aus dem Array

  const menuItemsRight = [
    !user && {
      label: <LoginButton />,
      key: 'login',
      icon: <LoginOutlined />,
    }
  ]

	
  return (
    <Layout>
      <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[selectedMenuItem]}
          onClick={handleMenuClick}
          items={menuItems} // Verwende das `items`-Prop
          style={{ flex: 1 }}
        />
        <Menu
            theme="dark"
            mode="horizontal"
            selectable={false}
            style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '150px' }}
            items={menuItemsRight}
        >

        </Menu>
      </Header>
      <Content style={{ padding: '20px' }}>
        <HappyProvider>
        <Routes>
          <Route path="/" element={<h1>Willkommen zur Startseite</h1>} />
          {user && <Route path="/table" element={<h1>Willkommen zur Startseite</h1>} />}
          <Route path="/auth/callback" element={<Callback onLogin={setUser} />} />
          {<Route path="/chapterText" element={<ChapterText />} />}
          {user && <Route path="/storyOption" element={<StoryOption />} />}
          {user && <Route path="/option" element={<Option />} />}
        </Routes>
          </HappyProvider>
      </Content>
    </Layout>
  );
}

// Stelle sicher, dass App in einem Router umgeben ist
export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}