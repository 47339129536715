import React from "react";
import EditableTable from '../components/EditableTable';
import {Col, Row} from "antd";

const chapterText = () => {
    return (
        <div>
            <Row>
                <Col span={20}  push={2}>
            <h1>Kapitel Publikations Texte</h1>
            <p>Zum Einstellen der Bot Texte beim Erscheinen eines neuen Kapitels.</p>
            <p><b>Aktiv</b> Schaltet den Text aus bzw. wieder ein</p>
            <p><b>Farbe:</b> (optional) Einstellung der Farbe des Balken Links von der Box</p>
            <p><b>Name:</b> (intern) Name des Textes, wird nicht in der Ausgabe benutzt, dient nur zum besseren Zuordnen</p>
            <p><b>Text:</b> Der Text der in der Box Angezeigt wird. Folgenten Platzhalter sind möglich: <b>%chapterName%</b> - Name des Kapitels,
                <b>%storyName%"</b> - Name der Gesichte und <b>%autor%"</b> - Name des Autors der Gesichte
            </p>
            <p><b>Story:</b> Für Welche Story soll der Text benutz werden</p>
            <p><b>Bild:</b> (optional) Welches Bild wird bei der Ausgabebox angezeigt</p>
            <EditableTable />
                </Col>
            </Row>
        </div>
    );
};

export default chapterText
