import React, {useState, useEffect} from 'react';
import {Table, Button, Form, Input, message, Switch   } from 'antd';
import api from '../axios';

const EditableTable = () => {
    const [data, setData] = useState([]);

    const [form] = Form.useForm();
    const [editingId, setEditingId] = useState('');


    const isEditing = (record) => record.id === editingId;

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setEditingId(record.id);
    };

    const deleteRow = (record) => {
        try {
            form.validateFields().then(values => {
                api.post('/options.php', { id: record.id, action: "removeChannel", ...values})
                    .then(response => {
                        const newData = data.filter((item) => item.id !== record.id);
                        setData(newData);
                        message.success('Datensatz erfolgreich entfernt');
                    })
                    .catch(error => {
                        message.error('Fehler beim entfernt des Datensatzes');
                    });
            });

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const cancel = () => {
        const newData = data.filter((item) => item.id !== editingId);
        setData(newData);
        setEditingId('');
    };

    // Neuer Datensatz erstellen
    const addNewRecord = () => {
        const newRecord = {
            id: "00000", // ID 0 für den neuen Datensatz
            name: '',
            server: '',
            active: true
        };
        setData([newRecord, ...data]);
        edit(newRecord);
    };

    // Änderungs speicherung
    const save = async (id) => {
        try {
            form.validateFields().then(values => {
                api.post('/options.php', { id: editingId, action: "addChannel", ...values})
                    .then(response => {
                        values["name"] = response.data.info.name;
                        values["server"] = response.data.info.serverName;
                        setData(data.map(item => item.id === editingId ? {...item, ...values} : item));
                        message.success('Datensatz erfolgreich aktualisiert');
                    })
                    .catch(error => {
                        message.error('Fehler beim Aktualisieren des Datensatzes');
                    });
            });
                setEditingId('');

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    useEffect(() => {
        api.get('/options.php')
            .then(response => {
                if(response.data.channels) {
                    let obj = JSON.parse(response.data.channels);
                    var newdata = [];
                    for(let i = 0; i < obj.length; i++) {
                        newdata.push({
                            active: true,
                            "id": obj[i]["id"],
                            "name": obj[i]["name"],
                            "server": obj[i]["serverName"],
                            }
                        );
                    }
                    setData(newdata);
                }

            })
            .catch(error => {
                message.error('Fehler beim Laden der Daten');
            });
    }, []);



    const handleActiveChange = (checked, record) => {
        const newData = data.map((item) => {
            if (item.id === record.id) {
                return { ...item, active: checked }; // Update the color in the record
            }
            return item;
        });
        const id = record.id;
        const active = checked;
        const action = "activeChange"
        api.post('/data.php', { id: id, active: active, action: action})
            .then(response => {
                message.success('Datensatz erfolgreich aktualisiert');
            })
            .catch(error => {
                message.error('Fehler beim Aktualisieren des Datensatzes');
            });
        setData(newData); // Update the state with the modified dataSource
    }


    const columns = [
        {
            title: 'Aktiv',
            dataIndex: 'active',
            key: 'active',
            editable: false,
            hidden: true,
            render: (active, record) => (
                <Switch checked={active} onChange={(checked) => handleActiveChange(checked ,record)}  checkedChildren="An" unCheckedChildren="Aus" />
            )
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            inputType: "text",
            editable: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
        },
        {
            title: 'Server',
            dataIndex: 'server',
            key: 'server',
            width: 150,
        },
        {
            title: 'Aktion',
            width: 250,
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Button
                onClick={() => save(record.id)}
                style={{ marginRight: 8 }}
            >
              Speichern
            </Button>
            <Button onClick={cancel}>Abbrechen</Button>
          </span>
                ) : (
                    <Button disabled={editingId !== ''} onClick={() => deleteRow(record)}>
                        Entfernen
                    </Button>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                keyId: col.id,
                editing: isEditing(record),
                form: form, // Hier wird das form-Objekt weitergegeben
            }),
        };
    });

    return (
        <div>
            <Button onClick={addNewRecord} type="primary" style={{ marginBottom: 16 }}>
                Neuen Datensatz erstellen
            </Button>
            <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                columns={mergedColumns}
                dataSource={data}
                rowClassName="editable-row"
                onRow={(record) => ({

                })}
                pagination={false}
            />
            </Form>
        </div>
    );
};


const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          required,
                          keyId,
                          color,
                          form, // Empfang des form-Objekts
                          ...restProps
                      }) => {
    const [inputNode, setInputNode] = useState('');



    useEffect(() => {
        switch (inputType) {
            case "textArea":
                setInputNode (<Input.TextArea showCount maxLength={4096} />);
                break;
            default:
                setInputNode (<Input />);
        }
    }, [inputType]); // Nur neu rendern, wenn sich inputType ändert




    return (
        <td {...restProps} >
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: required || false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableTable;
