import React, {useState, useEffect} from 'react';
import {Table, Button, Form, Input, message, Tag, Select, Space  } from 'antd';
import api from '../axios';

const EditableTable = () => {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [form] = Form.useForm();
    const [editingId, setEditingId] = useState('');


    const isEditing = (record) => record.id === editingId;

    const edit = (record) => {
        if(editingId !== "")
            return
        form.setFieldsValue({ ...record });
        setEditingId(record.id);
    };

    const cancel = () => {
        setEditingId('');
    };

    // Änderungs speicherung
    const save = (id) => {
        try {
            form.validateFields().then(values => {
                api.post('/dataStory.php', { id: editingId, action: "update",  ...values})
                    .then(response => {
                        if (response.data.status === "success") {
                            let tagUpdate = null;
                            if(response.data.tags != null) {
                                tagUpdate = {"tag" : [response.data.tags.id]};
                            }

                            const updatedValues = tagUpdate
                                ? { ...values, ...tagUpdate }
                                : values;

                            // Erfolg - Daten aktualisieren
                            setData(data.map(item => item.id === editingId ? { ...item, ...updatedValues } : item));

                            message.success('Datensatz erfolgreich aktualisiert');

                        } else {
                            // Fehler - Fehlermeldung anzeigen
                            message.error(response.data.message || 'Fehler beim Aktualisieren des Datensatzes');
                        }
                    })
                    .catch(error => {
                        message.error('Fehler beim Aktualisieren des Datensatzes');
                    });
            });

                setEditingId('');

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    useEffect(() => {
        api.get('/dataStory.php')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                message.error('Fehler beim Laden der Daten');
            });
    }, []);

    // Filter
    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };



    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            hidden: true
        },
        {
            title: 'FF Story Id',
            dataIndex: 'storyId',
            key: 'storyId',
            editable: false,
            width: 250,
        },
        {
            title: 'Erstelldatum',
            dataIndex: 'date',
            key: 'date',
            editable: false,
            width: 150,
            inputType: "text",
        },

        {
            title: (
                <Space>
                    Name
                    <Input
                        placeholder=""
                        value={searchText}
                        onChange={handleSearchTextChange}
                        style={{ width: 150 }}
                    />
                </Space>
            ),
            dataIndex: 'name',
            key: 'name',
            defaultSortOrder: 'descend',
            editable: true,
            required: true,
            inputType: "text",
            render: (text) => (
                <span>
        {text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ))}
      </span>
            ),
        },
        {
            title: 'Autor',
            dataIndex: 'autor',
            key: 'autor',
            editable: false,
            width: 150,
            inputType: "text",
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            editable: true,
            width: 150,
            inputType: "text",
            render: (_, {tag}) => {
                return tag !== "" ? (
                    <Tag color='geekblue' key={tag}>
                    {tag.toUpperCase()}
                </Tag>) : "";
            },


        },
        {
            title: 'Aktion',
            width: 250,
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Button
                onClick={() => save(record.id)}
                style={{ marginRight: 8 }}
            >
              Speichern
            </Button>
            <Button onClick={cancel}>Abbrechen</Button>
          </span>
                ) : (
                    <Button disabled={editingId !== ''} onClick={() => edit(record)}>
                        Bearbeiten
                    </Button>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                form: form, // Hier wird das form-Objekt weitergegeben
            }),
        };
    });

    return (
        <div>
            <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                columns={mergedColumns}
                dataSource={data}
                rowClassName="editable-row"
                onRow={(record) => ({
                    onDoubleClick: () => {
                        edit(record); // Bearbeitungsmodus durch Doppelklick aktivieren
                    },
                })}
                pagination={false}
            />
            </Form>
        </div>
    );
};


const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          required,
                          color,
                          form, // Empfang des form-Objekts
                          ...restProps
                      }) => {
    const [inputNode, setInputNode] = useState('');


    useEffect(() => {
        switch (inputType) {

            case "textArea":
                setInputNode (<Input.TextArea showCount maxLength={4096} />);
                break;
            default:
                setInputNode (<Input />);
        }
    }, [inputType]); // Nur neu rendern, wenn sich inputType ändert




    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: required || false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableTable;
